


























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapState, mapGetters } from "vuex";
import cookies from "js-cookie";

import {
  Header,
  Footer,
  FilterSearch,
  FilterInfoString
} from "@/components";
import {
  Button,
  Table,
  Thead,
  Tbody,
  TableRow,
  Pager,
  IconBase
} from "@/components/UI";
import PageLayout from "@/components/layouts/PageLayout.vue";
import Select from "@/components/UI/form/Select.vue";
import Plus from "@/components/UI/icon/Plus.vue";
import { MIN_CONTAINER_WIDTH } from "@/constants";
import ROLES from "@/config/roles";
import { PAGE_SHORT_NAMES } from '@/config/pages';
import TABLE_COLUMNS from '@/config/table-columns';

@Component({
  computed: {
    ...mapState("preapplicationList", [
      "sizePage",
      "activePage",
      "totalPages",
      "isLoaded",
      "isArchive",
    ]),
    ...mapGetters("preapplicationList", [
      "getPreApplicationList",
    ]),
    ...mapGetters("tableState", ["getColumnTitles"]),
    ...mapGetters("authorizationState", ["getRole"]),
    ...mapGetters("filterState", ["getFilterInfoString", "isActiveFilter"]),
  },
  methods: {
    ...mapActions("preapplicationList", [
      "getPreApplication",
      "changePage",
      "changeSizePage",
      "setActivePage",
    ]),
    ...mapActions("tableState", ["setTableContentType"]),
    ...mapActions("filterState", ["parseFilterFromLocaleStorage"]),
  },
  components: {
    Button,
    Tbody,
    Thead,
    Table,
    TableRow,
    Select,
    Pager,
    PageLayout,
    Header,
    Footer,
    IconBase,
    Plus,
    FilterInfoString,
    FilterSearch
  }
})
export default class PreAppHome extends Vue {
  public getPreApplication!: (savePagination: any) => void;
  public setActivePage!: (num: number) => void;
  public setTableContentType!: (contentType: PAGE_SHORT_NAMES) => void;
  public parseFilterFromLocaleStorage!: (pageName: string) => void;
  public getRole!: ROLES;

  get savePagination() {
    return this.$route.params.savePagination || false;
  }

  get getDefaultContainerWidth() {
    return window.localStorage.getItem(`preapp-container-width`) ||
    (MIN_CONTAINER_WIDTH + "px");
  }

  get getDefaultCellsWidth() {
    const columns = TABLE_COLUMNS[PAGE_SHORT_NAMES.preapp][this.getRole] ?? TABLE_COLUMNS[PAGE_SHORT_NAMES.preapp][ROLES.Manager];

    return columns!.map((column, index) => (
      window.localStorage.getItem(`preapp-column-${index}-width`) ||
      column.part * 100 + "%"
    ))
  }

  public preApplication() {
    this.parseFilterFromLocaleStorage("preapp");
    this.getPreApplication(this.savePagination);
  }

  public beforeMount() {
    this.setTableContentType(PAGE_SHORT_NAMES.preapp);
  }

  public mounted() {
    this.preApplication();
  }

  @Watch("$route")
  public beforeRouteUpdate(to: any, from: any, next: any) {
    this.preApplication();
  }
}
